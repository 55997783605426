import React, { useContext } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { CommonContext } from '../contexts/CommonContext';
import { useLocation } from 'react-router-dom';
import { height } from '@mui/system';

const styles = {
  productImg : {
    width:'100%',
    height:'250px',
    borderRadius:'5px 5px 0 0',
    borderRight:'1px solid #eaeaea'
  },
  productImgDeskCat : {
    width:'300px'
  },
  productImgDeskProd : {
    width:'500px'
  },
  productImgDeskHome : {
    width:'250px'
  },
  prodImgHeightHome : {
    width:'250px',
    borderRadius:'5px 5px 0 0',
    borderRight:'1px solid #eaeaea',
    // height:'180px'
  }
}

const classMap = {
  'categories' : 'prodImgDeskCat',
  'products'   : 'prodImgDeskProd',
  'home'       : 'prodImgDeskHome'
}


function ImageSlider({...props}) {

  const {isDesktop}  = useContext(CommonContext)
  const location     = useLocation()
  const path         = location.pathname.split('/')[1]

  return (
    <Swiper
    pagination={{
      dynamicBullets: true
    }}
    modules={[Pagination, Autoplay]}
    autoplay={{delay: 5000, disableOnInteraction: true}}
    className= {isDesktop ? classMap[path] : '' }>
    {
      props.imgUrls.map((url) => {
        return  <SwiperSlide key={url}>
        <img src={url} style={isDesktop ? 
          (path == 'categories' ? styles.productImgDeskCat :
           (path == 'home' ? styles.productImgDeskHome : styles.productImgDeskProd)) :
            (path == 'home' ? styles.prodImgHeightHome :  styles.productImg )} />
      </SwiperSlide>
      })
    }
  </Swiper>
  )
}

export default ImageSlider